@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap);
.home-container {
  height: 100vh;
  padding: 2rem 3rem;

  background-color: #080D22;
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.home-container .bubble-01,
.home-container .bubble-02 {
  position: absolute;
}

@-webkit-keyframes slideBubbleOne {
  0% {
    transform: translate3d(-20rem, 20rem, 0);
  }
  100% {
    transform: translate3d(0);
  }
}

@keyframes slideBubbleOne {
  0% {
    transform: translate3d(-20rem, 20rem, 0);
  }
  100% {
    transform: translate3d(0);
  }
}

.home-container .bubble-01 {
  height: 170vh;

  top: -15rem;
  left: -7rem;
  mix-blend-mode: exclusion;  
  

  -webkit-animation: 2s ease-out 0s 1 slideBubbleOne;  
  

          animation: 2s ease-out 0s 1 slideBubbleOne;
}

@-webkit-keyframes slideBubbleTwo {
  0% {
    transform: translate3d(20rem, -20rem, 0);
  }
  100% {
    transform: translate3d(0);
  }
}

@keyframes slideBubbleTwo {
  0% {
    transform: translate3d(20rem, -20rem, 0);
  }
  100% {
    transform: translate3d(0);
  }
}

.home-container .bubble-02 {
  height: 150vh;

  top: -12rem;
  right: -4rem;  
  mix-blend-mode: exclusion;

  -webkit-animation: 2s ease-out 0s 1 slideBubbleTwo;

          animation: 2s ease-out 0s 1 slideBubbleTwo;
}

.home-container a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 500;
}

.home-container * {
  z-index: 1200;
  
  display: flex;
  justify-content: space-between;
}

.home-container .header img {
  cursor: pointer;
}

.home-container .content {  
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@-webkit-keyframes slideLogo {
  0% {
    transform: translate3d(0rem, 5rem, 0);
  }
  100% {
    transform: translate3d(0);
  }
}

@keyframes slideLogo {
  0% {
    transform: translate3d(0rem, 5rem, 0);
  }
  100% {
    transform: translate3d(0);
  }
}

.home-container .content * {
  font-family: 'Fira Code';
  font-weight: 300;
  color: white;

  -webkit-animation: 2s ease-out 0s 1 slideLogo;

          animation: 2s ease-out 0s 1 slideLogo;
}

.home-container .content h1 {
  font-size: 2.5rem;
}

.home-container .content h1 span {
  color: #00BDBF;
}

.home-container .content h2 {
  margin-top: 1rem;

  font-size: 1.2rem;
}

@media screen and (max-width: 900px) {
  .home-container .bubble-02 {
    height: 60vh;
    top: 20vh;
  }

  .home-container .bubble-01 {
    height: 70vh;
    top: 10vh;
  }

  .home-container {
    text-align: center;
  }

}
.landpage-container {
  min-height: 100vh;
  padding-top: 5.5rem;
}

@media screen and (max-width: 900px) {
  .landpage-container {
    padding: 0;
  }
}
@-webkit-keyframes show {
  0% { top: -5.5rem; }
  100% { top: 0; }
}

@keyframes show {
  0% { top: -5.5rem; }
  100% { top: 0; }
}

@-webkit-keyframes hide {
  0% { top: 0; }
  100% { top: -5.5rem; }
}

@keyframes hide {
  0% { top: 0; }
  100% { top: -5.5rem; }
}

.navbar-container {
  width: 100%;
  height: 5.5rem;
  padding: 0 3rem;

  z-index: 1200;
  position: fixed;
  background-color: #17274C;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navbar-container.fixed {
  -webkit-animation: 1s show;
          animation: 1s show;
  top: 0;
}

.navbar-container.hide {
  -webkit-animation: 1s hide;
          animation: 1s hide;
  top: -5.5rem;
}

@media screen and (max-width: 900px) {
  .navbar-container {
    display: none;
  }
}

.navbar-container .logo img {
  height: 3.5rem;

  cursor: pointer;
}

.navbar-container .menu {
  list-style: none;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navbar-container .menu li {
  margin: 0 1.2rem;
}

.navbar-container .menu li a {
  cursor: pointer;

  color: rgba(255, 255, 255, 0.9);
  font-size: 1rem;
  font-weight: 500;
  font-family: Roboto;
  text-decoration: none;
}

.navbar-container .language {
  cursor: pointer;
}
.footer-container {
  width: 100%;
  padding: 3rem;

  z-index: 1200;
  background-color: #080D22;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .footer-container {
    height: auto;

    flex-direction: column;
    justify-content: center;
  }
}

.footer-container .social {
  color: white;
  font-size: 1.1rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-container .social p {
  margin-bottom: 1rem;
}

.footer-container .social ul {
  width: 100%;
  margin-top: 1rem;
  list-style: none;
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-container .social ul li {
  width: 1.8rem;
  height: 1.8rem;

  border-radius: 50rem;
  background-color: white;
}

.footer-container .social ul li:hover {
  background-color: transparent;
}

.footer-container .social ul li img {
  width: 1.8rem;
  height: 1.8rem;
  
  cursor: pointer;

  border: none;  
  border-radius: 50rem;
  
  -webkit-filter: invert(100%);
  
          filter: invert(100%);
}

.footer-container .social ul li img:hover {
  border: 1px solid white;

  -webkit-filter: none;

          filter: none;
}

.footer-container .logos {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .footer-container .logos {
    margin: 3rem 0;

    flex-direction: column;
  }
}

.footer-container .logos img {
  margin: 1rem 2rem;
}

.footer-container .address {
  color: white;
  font-size: 1.1rem;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.footer-container .address p {
  width: 16rem;
  margin-bottom: 2rem;

  text-align: end;
}

.footer-container .address button {
  padding: 0.8rem 3rem;
  font-weight: 300;
}
.landpage-container .introduction-section {
  padding: 6rem 4rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .landpage-container .introduction-section {
    padding: 3rem 2rem;
  }
}

.landpage-container .introduction-section .content {
  width: 33rem;
}

@media screen and (max-width: 900px) {
  .landpage-container .introduction-section .content {
    width: 100%;
  }
}

.landpage-container .introduction-section .content h1 {
  font-size: 2.5rem;
  color: #17274C;
}

@media screen and (max-width: 900px) {
  .landpage-container .introduction-section .content h1 {
    font-size: 2rem;
  }
}

.landpage-container .introduction-section .content h2 {
  margin-top: 1rem;

  font-size: 2rem;
  color: #0091B7;
}

@media screen and (max-width: 900px) {
  .landpage-container .introduction-section .content h2 {
    font-size: 1.5rem;
  }
}

.landpage-container .introduction-section .content p {
  margin-top: 2rem;
  
  font-size: 1.5rem;
  color: #17274C;
}

@media screen and (max-width: 900px) {
  .landpage-container .introduction-section .content p {
    font-size: 1rem;
  }
}

.landpage-container .introduction-section img {
  height: 25rem;
}

@media screen and (max-width: 900px) {
  .landpage-container .introduction-section img {
    display: none;
  }
}
.landpage-container .feedback-section {
  padding: 4rem;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .landpage-container .feedback-section {
    padding: 3rem 2rem;
  }
}

.landpage-container .feedback-section .card {
  width: 50rem;
  padding: 4rem 8rem;

  box-shadow: 0px 14px 30px rgba(19, 102, 124, 0.14);
  border-radius: 18px;
  color: #17274C;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .landpage-container .feedback-section .card {
    width: 100%;
    padding: 4rem 2rem;
  }
}

.landpage-container .feedback-section .card img {
  margin-top: -8rem;
  transform: scale(0.8);
  height: 20vh;
  border-radius: 50vh;
}

.landpage-container .feedback-section .card h2 {
  margin: 1rem 0 2rem;
  
  font-size: 1rem;
  opacity: 0.6;
}

.landpage-container .feedback-section .card p {
  font-size: 1.2rem;
  font-weight: 500;
}

.landpage-container .feedback-section .actions {
  width: 100%;
  margin-top: 3rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.landpage-container .feedback-section .actions svg {
  margin: 0 2rem;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .landpage-container .feedback-section .actions svg {
    margin: 0;
    cursor: pointer;
  }
}

.landpage-container .feedback-section .actions .dot {
  width: 0.8rem;
  height: 0.8rem;
  margin: 0 2rem;

  border-radius: 50rem;
  background-color: #C4C4C4;
}

@media screen and (max-width: 900px) {
  .landpage-container .feedback-section .actions .dot {
    width: 0.5rem;
    height: 0.5rem;
    margin: 0 1.5rem;
  }
}

.landpage-container .feedback-section .actions .dot.selected {
  background-color: #0091B7;
}
.landpage-container .tips-section {
  padding: 6rem 4rem;

  background-color: #080D22;
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .landpage-container .tips-section {
    padding: 3rem 2rem;
  }
}

.landpage-container .tips-section span {
  color: #00BDBF;
}

.landpage-container .tips-section .header {
  width: 50rem;
  margin: 2.5rem 0;

  font-size: 1rem;
  text-align: center;
}

@media screen and (max-width: 900px) {
  .landpage-container .tips-section .header {
    width: 100%;

    font-size: 0.7rem;
  }
}

.landpage-container .tips-section .content {
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

@media screen and (max-width: 900px) {
  .landpage-container .tips-section .content {
    width: 100%;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
}

.landpage-container .tips-section .content .item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.landpage-container .tips-section .content .item .header {
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.landpage-container .tips-section .content .item .header * {
  font-weight: 500;
}

.landpage-container .tips-section .content .item .header h2:first-of-type {
  margin-right: 1.5rem;

  font-size: 2rem;
}

.landpage-container .tips-section .content .item .header h2:nth-of-type(2) {
  width: 15rem;

  font-size: 1.5rem;
  text-align: left;
  text-align: initial;
}

.landpage-container .tips-section .content .item .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.landpage-container .tips-section .content .item .content p {
  width: 20rem;
  margin: 0.5rem 0;

  font-size: 1.1rem;
}
.landpage-container .clients-section {
  padding: 7rem 4rem;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.landpage-container .clients-section .header {
  width: 100%;
  
  text-align: center;
  font-size: 1.2rem;
  color: #17274C;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.landpage-container .clients-section .content {
  width: 100%;
  margin-top: 2rem;

  display: grid;
  grid-template-columns: repeat(4, 25%);
  justify-items: center;
}

.landpage-container .clients-section .content img {
  margin: 5rem 0;

  cursor: pointer;
  -webkit-filter: saturate(0);
          filter: saturate(0);
}

.landpage-container .clients-section .content img:hover {
  -webkit-filter: saturate(1);
          filter: saturate(1);
}

@media screen and (max-width: 900px) {
  .landpage-container .clients-section {
    padding: 3rem 2rem;
  }

  .landpage-container .clients-section .header {
    justify-content: center;
  }
  
  .landpage-container .clients-section .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .landpage-container .clients-section .content img {
    margin: 2rem 0;

    -webkit-filter: saturate(1);

            filter: saturate(1);
  }
}
.landpage-container .advantage-section {
  padding: 4rem;

  background-color: #080D22;
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .landpage-container .advantage-section {
    padding: 1rem 2rem;
  }
}

.landpage-container .advantage-section .header h1 {
  margin: 3rem 0;
  
  font-size: 1.5rem;
  font-weight: 500;
}

@media screen and (max-width: 900px) {
  .landpage-container .advantage-section .header h1 {
    text-align: center;
  }
}

.landpage-container .advantage-section .content {
  width: 100%;
  padding-bottom: 1rem;

  overflow: auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .landpage-container .advantage-section .content {
    transform: scale(0.85);
    justify-content: flex-start;
  }
}

.landpage-container .advantage-section .content table {
  border-collapse: collapse;
}

.landpage-container .advantage-section .content tr {
  text-align: center;
}

.landpage-container .advantage-section .content tr td {
  padding: 1rem 3rem;
}

.landpage-container .advantage-section .content tr td:first-of-type {
  width: 30rem;

  font-size: 1.2rem;
  text-align: left;
  text-align: initial;

  border-right: 0.15rem solid #17274c73;
}

.landpage-container .advantage-section .content tr td:nth-of-type(2) {
  border-right: 0.15rem solid #17274c73;
}

.landpage-container .advantage-section .content tr td:first-of-type span {
  color: #253D76;
}

.landpage-container .advantage-section .content tr td svg {
  width: 10rem;
  font-size: 2rem;
}
.landpage-container .ourteam-section {
  padding: 4rem;

  background-color: #080D22;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .landpage-container .ourteam-section {
    padding: 3rem 2rem;
  }
}

.landpage-container .ourteam-section img {
  height: 30rem;
}

@media screen and (max-width: 900px) {
  .landpage-container .ourteam-section img {
    display: none;
  }
}

.landpage-container .ourteam-section .content {
  width: 33rem;
  text-align: center;
}

@media screen and (max-width: 900px) {
  .landpage-container .ourteam-section .content {
    width: 100%;
  }
}

.landpage-container .ourteam-section .content h1 {
  font-size: 2.5rem;
  color: #0091B7;
}

@media screen and (max-width: 900px) {
  .landpage-container .ourteam-section .content h1 {
    font-size: 2rem;
  }
}

.landpage-container .ourteam-section .content p {
  margin-top: 2rem;

  font-size: 1.5rem;
  color: white;
}

@media screen and (max-width: 900px) {
  .landpage-container .ourteam-section .content p {
    font-size: 1rem;
  }
}
.landpage-container .aboutus-section {
  padding: 4rem;
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .landpage-container .aboutus-section {
    padding: 3rem 2rem;
  }
}

.landpage-container .aboutus-section .content {
  width: 100%;

  color: #17274C;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landpage-container .aboutus-section .content h4 {
  font-size: 1.1rem;
  font-family: 'Fira Code';
  font-weight: 500;
}

.landpage-container .aboutus-section .content h1 {
  margin-top: 2rem;

  font-size: 2.5rem;
}

.landpage-container .aboutus-section .content button {
  margin-top: 3rem;
}
.landpage-container .map-section {
  padding: 6rem 10rem;

  background-color: #080D22;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .landpage-container .map-section {
    padding: 3rem 2rem;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .landpage-container .map-section svg {
    width: 80%;
    height: auto;
    margin: 5vh 0;
  }
}

.landpage-container .map-section .content {
  color: white;
}

.landpage-container .map-section .content h1 {
  font-size: 2.5rem;
  margin-bottom: 3rem;
}

.landpage-container .map-section .content .row {
  width: 20rem;
  margin-bottom: 3rem;

  color: #00BDBF;
  opacity: 0.8;

  line-height: 1.6rem;
  font-size: 1rem;
  font-weight: 400;
}

.landpage-container .map-section .content .row p:first-of-type {
  color: white;
}

.landpage-container .map-section .content button {
  background: linear-gradient(to right, #0091B7, #00BDBF);
  padding: 1.4rem 5rem;
}
.landpage-container .partners-section {
  padding: 4rem;
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .landpage-container .partners-section {
    padding: 3rem 2rem;
  }
}

.landpage-container .partners-section .content {
  width: 100%;

  color: #17274C;
  text-align: center;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

@media screen and (max-width: 900px) {
  .landpage-container .partners-section .content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.landpage-container .partners-section .content .item {
  margin: 2rem 4rem;
}

.landpage-container .partners-section .content .item h2 {
  margin-top: 2rem;
}
.projects-container {
  min-height: 100vh;
  padding: 5.5rem 8rem 0 8rem;

  color: #17274C;
}

.projects-container .content {
  width: 100%;
  padding: 7rem 0;  

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.projects-container .content .header {
  width: 100%;
}

.projects-container .content h1 {
  margin-bottom: 3rem;

  font-size: 2.5rem;
}

.projects-container .content .logos {
  width: 100%;

  display: grid;
  grid-template-columns: repeat(4, 25%);
  justify-items: center;
}

.projects-container .content .logos img {
  margin: 2rem 0;

  cursor: pointer;
  -webkit-filter: saturate(0);
          filter: saturate(0);
}

.projects-container .content .logos img:hover {
  -webkit-filter: saturate(1);
          filter: saturate(1);
}

@media screen and (max-width: 900px) {
  .projects-container {
    padding: 0;
  }

  .projects-container .content {
    padding: 5rem 2rem;
    justify-content: center;
  }

  .projects-container .content .logos {
    grid-template-columns: repeat(2, 50%);
  }

  .projects-container .content .logos img {
    height: 4rem;

    -webkit-filter: saturate(1);

            filter: saturate(1);
  }
}

.projects-detail-container {
  min-height: 100vh;
  padding: 5.5rem 8rem 0 8rem;

  color: #17274C;
}

@media screen and (max-width: 900px) {
  .projects-detail-container {
    padding: 0;
  }
}

.projects-detail-container .content {
  width: 100%;
  padding: 7rem 0;  

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (max-width: 900px) {
  .projects-detail-container .content {
    padding: 5rem 2rem;
    justify-content: center;
  }
}

.projects-detail-container .content .about {
  width: 35rem;
}

.projects-detail-container .content .about h1 {
  margin-bottom: 3rem;

  font-size: 2.5rem;
}

.projects-detail-container .content .about p {
  margin-bottom: 3rem;

  text-align: justify;
  font-size: 1rem;
}

.projects-detail-container .content .about h3 {
  margin-bottom: 1.5rem;

  font-size: 1rem;
}

.projects-detail-container .content .about .technologies {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.projects-detail-container .content .about .technologies .chip {
  padding: 0.7rem 1rem;
  margin: 0rem 1rem 1rem 0rem;

  border-radius: 50rem;
  background-color: rgba(0, 0, 0, 0.15);
}

.projects-detail-container .pictures {
  width: 35rem;
}

.projects-detail-container .pictures .logo {
  width: 100%;
  margin-bottom: 5rem;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .projects-detail-container .pictures .logo {
    margin: 0;
    justify-content: center;
  }
}

.projects-detail-container .pictures .logo img {
  margin-right: 3rem;
  transform: scale(1.5);
}

@media screen and (max-width: 900px) {
  .projects-detail-container .pictures .logo img {
    margin: 3rem 0;
  }
}

.projects-detail-container .pictures .devices {
  width: 100%;
  height: 20rem;

  position: relative;
}

@media screen and (max-width: 900px) {
  .projects-detail-container .pictures .devices {
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.projects-detail-container .pictures .devices img {
  max-height: 16rem;
  
  position: absolute;
}

.projects-detail-container .pictures .devices img:nth-of-type(1) {
  top: 0rem;
  left: 0;  
}

.projects-detail-container .pictures .devices img:nth-of-type(2) {
  top: 6rem;
  right: 0;
}

.projects-detail-container .pictures .devices .mobile {
  left: auto !important;
  right: 0;
}

@media screen and (max-width: 900px) {
  .projects-detail-container .pictures .devices img {
    height: 12rem;
    margin: 1rem 0;

    top: 0;
    position: static;
    position: initial;
  }
}
.projects-detail-container .others .header {
  width: 100%;
  
  text-align: center;
  font-size: 1.2rem;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.projects-detail-container .others .content {
  width: 100%;
  padding: 1.5rem 0 5rem 0;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.projects-detail-container .others .content img {
  margin: 1.5rem 0;
  cursor: pointer;
  
  -webkit-filter: saturate(0);
  
          filter: saturate(0);
}

.projects-detail-container .others .content img:hover {
  -webkit-filter: saturate(1);
          filter: saturate(1);
}

@media screen and (max-width: 900px) {
  .projects-detail-container .others .content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .projects-detail-container .others .header {
    padding: 0 2rem;
    justify-content: center;
  }

  .projects-detail-container .others .content img {
    margin: 3rem 0;
    -webkit-filter: saturate(1);
            filter: saturate(1);
  }
}
.contact-us-component {
  width: 100%;
  padding: 6rem 4rem;

  background-color: #080D22;
  color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-us-component h3 {
  margin-bottom: 2rem;

  font-weight: 400;
  font-family: 'Fira Code';
}

.contact-us-component h1 {
  margin-bottom: 3rem;

  color: #0091B7;
  font-size: 3rem;
}

.contact-us-component button {
  background: linear-gradient(to left, #0091B7, #00BDBF);
}

@media screen and (max-width: 900px) {
  .contact-us-component {
    padding: 4rem 2rem;
  }

  .contact-us-component h1 {
    font-size: 2rem;
  }
}
.contact-us-container {
  min-height: 100vh;
  padding: 5.5rem 8rem 0 8rem;

  background-color: #080D22;

  display: flex;  
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .contact-us-container {
    padding: 0 2rem;
  }
}

.contact-us-container .header {
  margin: 7rem 0;

  color: #00BDBF;
  font-size: 1.5rem;
}

@media screen and (max-width: 900px) {
  .contact-us-container .header {
    font-size: 0.8rem;
  }
}

.contact-us-container form {
  width: 60%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
}

@media screen and (max-width: 1200px) {
  .contact-us-container form {
    width: 100%;
    
    flex-direction: column;
    justify-content: center;
  }
}

.contact-us-container form .col {
  display: flex;
  flex-direction: column; 
  justify-content: space-around; 
  align-items: flex-start;
}

@media screen and (max-width: 1200px) {
  .contact-us-container form .col {
    margin: 2rem 0;
  }
}

.contact-us-container form .col label:nth-of-type(2) {
  margin-top: 2rem;
}

.contact-us-container form .col button {
  width: 100%;
  margin-top: 2rem;

  background-color: white;
  color:  #17274C;
  font-weight: 600;
}

.contact-us-container .whatsapp {
  width: 100%;
  margin: 10rem 0;

  color: white;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
}

.contact-us-container .whatsapp img {
  height: 20rem;
}

@media screen and (max-width: 900px) {
  .contact-us-container .whatsapp img {
    display: none;
  }
}

.contact-us-container .whatsapp .content h1 {
  width: 25rem;
  margin-bottom: 2rem;
}

@media screen and (max-width: 900px) {
  .contact-us-container .whatsapp .content h1 {
    width: 100%;
    font-size: 1.6rem;
  }
}

.contact-us-container .whatsapp .content p {
  width: 25rem;
  font-size: 1.1rem;
  margin-bottom: 3rem;
}

@media screen and (max-width: 900px) {
  .contact-us-container .whatsapp .content p {
    width: 100%;
    font-size: 0.9rem;
    margin-bottom: 3rem;
  }
}

.contact-us-container .whatsapp .content button {
  width: 15rem;
  height: 4rem;
  margin-bottom: 3rem;
  background: linear-gradient(to left, #25D366, #128C7E);

  display: flex;
  justify-content: space-around;
  align-items: center;
}

.contact-us-container .whatsapp .content button img {
  width: 2.5rem;
  height: 2.5rem;
}
.about-us-container {
  min-height: 100vh;
  padding: 12rem 0rem 0 8rem;

  background-color: #080D22;
  color: white;
  
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 1200px) {
  .about-us-container {
    padding: 7rem 0;
    flex-direction: column;
    align-items: center;
  }
}

.about-us-container .menu {
  width: 9rem;
  margin-top: 1rem;
}

@media screen and (max-width: 1200px) {
  .about-us-container .menu {
    width: 100%;
    margin: 0 0 3rem 0;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}

.about-us-container .menu p {
  margin-bottom: 1.5rem;

  cursor: pointer;

  font-family: 'Fira Code';
}

.about-us-container .menu p span {
  color: #00BDBF;
}
.about-us-container .services-container {
  width: 100%;
}

.about-us-container .services-container .content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.about-us-container .services-container .content .col {
  width: 30rem;
}

@media screen and (max-width: 900px) {
  .about-us-container .services-container .content .col {
    width: 100%;
    padding: 0 2rem;
  }  
}

.about-us-container .services-container .content .col h4 {
  margin: 2rem 0;

  font-size: 1.5rem;
  font-weight: 400;
}

.about-us-container .services-container .content .col p {
  margin: 1.5rem 0;

  font-size: 1.1rem;
}

.about-us-container .services-container .content .col p span {
  color: #00BDBF;
}

@media screen and (max-width: 900px) {
  .about-us-container .services-container .content img {
    display: none;
  }
}
.about-us-container .process-container {
  width: 100%;
}

.about-us-container .process-container .content {
  padding: 0 10rem;
  
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.about-us-container .process-container .content .step {
  margin: 4rem 0 5rem 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.about-us-container .process-container .content .step.reverse {
  flex-direction: row-reverse;
}

.about-us-container .process-container .content .step h2 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 1rem;

  font-size: 2rem;
  line-height: 2rem;
  background-color: white;
  color: black;
}

.about-us-container .process-container .content .step p {
  width: 20rem;
  margin-top: 3rem;

  font-size: 1.3rem;
}

.about-us-container .process-container .content .step img {
  height: 25rem;
}

@media screen and (max-width: 900px) {
  .about-us-container .process-container .content {
    padding: 0 2rem;
  }

  .about-us-container .process-container .content .step {
    margin: 3rem 0;
    flex-direction: column;
  }

  .about-us-container .process-container .content .step.reverse {
    flex-direction: column;
  }

  .about-us-container .process-container .content .step p {
    width: 100%;
  }

  .about-us-container .process-container .content .step img {
    height: 15rem;
    margin: 2rem 0;
  }
}
.about-us-container .who-we-are-container {
  width: 100%;
}

.about-us-container .who-we-are-container .content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.about-us-container .who-we-are-container .content .col {
  width: 30rem;
}

@media screen and (max-width: 900px) {
  .about-us-container .who-we-are-container .content .col {
    width: 100%;
    padding: 0 2rem;
  }  
}

.about-us-container .who-we-are-container .content .col p {
  margin: 1.5rem 0;

  font-size: 1.1rem;
}

@media screen and (max-width: 900px) {
  .about-us-container .who-we-are-container .content img {
    display: none;
  }
}
html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font: 400 14px Montserrat, sans-serif;
  background: #f0f0f5;
  -webkit-font-smoothing: antialiased;
}

p {
  line-height: 2.5rem;
}

h1, h2, h3 {
  line-height: 2.5rem;
}

*::-webkit-scrollbar {
  background-color: #babac052;
  width:9px;
  border-radius:16px;
  position: fixed;
}

*::-webkit-scrollbar-thumb {
  background-color:#babac0;
  border-radius:16px;
}

button {
  padding: 1.3rem 2rem;

  cursor: pointer;

  color: white;
  font-size: 1rem;
  font-family: 'Fira Code';

  background-color: #17274C;
  border: none;
  border-radius: 8rem;
}

label {
  margin-bottom: 0.5rem;

  color: white;
  font-size: 1rem;
  font-family: 'Fira Code';
}

input, textarea {
  padding: 1rem;

  background-color: #14203E;
  border-radius: 3px;
  border: none;
  resize: none;

  font-family: 'Fira Code';
  font-size: 1.1rem;
  color: white;
}
