.about-us-container .who-we-are-container {
  width: 100%;
}

.about-us-container .who-we-are-container .content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.about-us-container .who-we-are-container .content .col {
  width: 30rem;
}

@media screen and (max-width: 900px) {
  .about-us-container .who-we-are-container .content .col {
    width: 100%;
    padding: 0 2rem;
  }  
}

.about-us-container .who-we-are-container .content .col p {
  margin: 1.5rem 0;

  font-size: 1.1rem;
}

@media screen and (max-width: 900px) {
  .about-us-container .who-we-are-container .content img {
    display: none;
  }
}