@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap');

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font: 400 14px Montserrat, sans-serif;
  background: #f0f0f5;
  -webkit-font-smoothing: antialiased;
}

p {
  line-height: 2.5rem;
}

h1, h2, h3 {
  line-height: 2.5rem;
}

*::-webkit-scrollbar {
  background-color: #babac052;
  width:9px;
  border-radius:16px;
  position: fixed;
}

*::-webkit-scrollbar-thumb {
  background-color:#babac0;
  border-radius:16px;
}

button {
  padding: 1.3rem 2rem;

  cursor: pointer;

  color: white;
  font-size: 1rem;
  font-family: 'Fira Code';

  background-color: #17274C;
  border: none;
  border-radius: 8rem;
}

label {
  margin-bottom: 0.5rem;

  color: white;
  font-size: 1rem;
  font-family: 'Fira Code';
}

input, textarea {
  padding: 1rem;

  background-color: #14203E;
  border-radius: 3px;
  border: none;
  resize: none;

  font-family: 'Fira Code';
  font-size: 1.1rem;
  color: white;
}